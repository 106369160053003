html {
    background-color:rgb(44, 42, 42);
    color: gray;
    font-family: Arial, Helvetica, sans-serif;
    padding-left: 15em;
    padding-right: 15em;
    padding-top: 5em;
}

body {
    background-color: rgb(14, 12, 12);
    margin: auto;
    padding-left: 10em;
    padding-right: 10em;
}

p {
    text-indent: 2em;
    font-size: 2em;
}

a {
    color: yellow;
}

.flex-container {
    display: flex;
}

.flex-container-column {
    display: flex;
    flex-direction: column;
}

.flex-justify-content-end {
    justify-content: flex-end;
}
.margin-auto {
    margin: auto;
}

